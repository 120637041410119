import { usePopperTooltip } from 'react-popper-tooltip';

import {
  EnumsGamePackAudience,
  EnumsGamePackDifficulty,
  type ModelsDetailSettings,
} from '@lp-lib/api-service-client/public';

import { GamePackUtils } from '../../../src/components/Game/GamePack/utils';
import { PlayerRangeUtils } from '../../../src/components/Game/PlayerRangeUtils';
import { AllTogetherIcon } from '../../../src/components/icons/AllTogetherIcon';
import { FilledCheckIcon } from '../../../src/components/icons/CheckIcon';
import { GameModeIcon } from '../../../src/components/icons/GameModeIcon';
import { MedalIcon } from '../../../src/components/icons/MedalIcon';
import { RefreshIcon } from '../../../src/components/icons/RefreshIcon';
import { TeamIcon } from '../../../src/components/icons/TeamIcon';
import { ThumbsUpIcon } from '../../../src/components/icons/ThumbsUpIcon';
import { TimerIcon } from '../../../src/components/icons/TimerIcon';
import { UnionIcon } from '../../../src/components/icons/UnionIcon';
import { type IFeatureChecker } from '../../../src/components/Product/FeatureChecker';
import { useFeatureQueryParam } from '../../../src/hooks/useFeatureQueryParam';
import { type GamePack } from '../../../src/types/game';
import { formatCurrency } from '../../../src/utils/currency';
import { TimeUtils } from '../../../src/utils/time';
import { isGamePackPlayTogether } from './utils';

export function NewGamePackBadge(props: {
  gamePack: GamePack;
}): JSX.Element | null {
  if (props.gamePack.played) return null;
  const expiresAt = props.gamePack.detailSettings?.markAsNew?.expiredAt;
  if (!expiresAt || new Date(expiresAt) < new Date()) return null;
  return (
    <CustomGamePackCardBadge
      styles={{
        bg: 'bg-gradient-to-br from-[#5B31FF] to-[#4312D1]',
      }}
    >
      New
    </CustomGamePackCardBadge>
  );
}

export function FreeGamePackBadge(props: {
  gamePack: GamePack;
  features: IFeatureChecker;
}): JSX.Element | null {
  const { features, gamePack } = props;
  const enabled = useFeatureQueryParam('game-pack-card-free-badge');
  if (
    features.hasUnlimitedOndGamePlayAccess() ||
    !features.canAccessGamePackForOnd(gamePack) ||
    !enabled
  )
    return null;

  return (
    <CustomGamePackCardBadge
      styles={{
        bg: 'bg-gradient-to-br from-[#01D193] to-[#009166]',
      }}
    >
      Free
    </CustomGamePackCardBadge>
  );
}

const minVoteCount = 10;
const minRating = 0.85;
export function ThumbsUpBadge(props: {
  gamePack: GamePack;
}): JSX.Element | null {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'hover',
      placement: 'bottom',
    });

  const stats = props.gamePack.blockVoteStats;
  const numVotes = !stats ? 0 : stats.upVoteCount + stats.downVoteCount;
  const rating = stats?.rating ?? 0;

  if (!stats || numVotes < minVoteCount || rating < minRating) return null;

  const ratingPercent = Math.round(rating * 100);
  return (
    <div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `h-auto rounded-md text-center font-medium text-white normal-case tracking-normal text-xs px-3 py-2 bg-lp-gray-003 shadow-lg`,
          })}
        >
          {ratingPercent}% thumbs up rating!
        </div>
      )}
      <div ref={setTriggerRef}>
        <CustomGamePackCardBadge
          styles={{
            bg: 'bg-lp-gray-003',
            size: 'px-2 h-6 gap-1',
          }}
        >
          <ThumbsUpIcon />
          <>{ratingPercent}%</>
        </CustomGamePackCardBadge>
      </div>
    </div>
  );
}

export function CustomGamePackCardBadge(props: {
  styles?: Partial<{
    bg: string;
    size: string;
    text: string;
  }>;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div
      className={`
        ${props.styles?.bg ?? ''} 
        ${props.styles?.size ?? 'px-4 h-6'}
        ${
          props.styles?.text ??
          'text-white text-xs font-bold text-center uppercase tracking-wide truncate'
        }
        rounded-[4px] shadow-gamecard-badge flex items-center justify-center
    `}
    >
      {props.children}
    </div>
  );
}

export function BadgeContainer(props: {
  text: string;
  className?: string;
}): JSX.Element {
  return (
    <div
      className={`flex items-center justify-center
      bg-warning text-black absolute bottom-0
      text-3xs font-bold w-full h-4 rounded-b-lg
      border border-secondary border-t-0 ${props.className}`}
    >
      {props.text}
    </div>
  );
}

export function FeaturedGameBadge(props: {
  pack: GamePack;
}): JSX.Element | null {
  if (!props.pack.isFeatured) return null;

  return <BadgeContainer text={`Featured Game`} />;
}

export function GamePackAudienceDisclaimer(props: {
  audience?: ModelsDetailSettings['audience'] | null;
  className?: string;
}): JSX.Element | null {
  if (props.audience !== EnumsGamePackAudience.GamePackAudienceUsCentric)
    return null;
  return (
    <div className={`${props.className ?? 'italic text-xs text-icon-gray'}`}>
      Certain game elements may be challenging for non-native English speakers
      or those with less US cultural fluency.
    </div>
  );
}

export function GamePackDuration(props: {
  pack: GamePack;
  strong?: boolean;
}): JSX.Element {
  return (
    <span
      className={`flex-shrink-0 flex items-center ${
        props.strong ? 'gap-2 text-base font-bold' : 'gap-1.5 text-sms'
      }`}
    >
      <TimerIcon
        className={`fill-current ${
          props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
        }`}
      />
      {TimeUtils.DurationFormattedHumanMinutes(
        props.pack.approximateDurationSeconds * 1000,
        'Min'
      )}
    </span>
  );
}

export function GamePackDifficulty(props: {
  pack: GamePack;
  strong?: boolean;
}): JSX.Element | null {
  if (!props.pack.detailSettings?.gameDifficulty) return null;

  return (
    <div
      className={`flex-shrink-0 flex items-center ${
        props.strong ? 'gap-2 text-base font-bold' : 'gap-1.5 text-sms'
      }`}
    >
      <GameModeIcon
        className={`fill-current ${
          props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
        }`}
      />
      <p>
        Difficulty:{' '}
        <span
          className={`capitalize ${
            props.pack.detailSettings.gameDifficulty ===
            EnumsGamePackDifficulty.GamePackDifficultyHard
              ? 'text-red-006'
              : ''
          }`}
        >
          {props.pack.detailSettings.gameDifficulty}
        </span>
      </p>
    </div>
  );
}

export function GamePackPlayerRange(props: {
  pack: GamePack;
  strong?: boolean;
  fallbackMax?: number;
}): JSX.Element {
  return (
    <span
      className={`flex-shrink-0 flex items-center ${
        props.strong ? 'gap-2 text-base font-bold' : 'gap-1.5 text-sms'
      }`}
    >
      <TeamIcon
        className={`fill-current ${
          props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
        }`}
      />
      {PlayerRangeUtils.Format(
        props.pack.playerRange,
        'Participant',
        props.fallbackMax
      )}
    </span>
  );
}

export function GamePackCompetitionLevel(props: {
  pack: GamePack;
  strong?: boolean;
}) {
  if (!props.pack.detailSettings?.competitionLevel) return null;

  return (
    <div
      className={`flex-shrink-0 flex items-center ${
        props.strong ? 'gap-2 text-base font-bold' : 'gap-1.5 text-sms'
      }`}
    >
      <UnionIcon
        className={`fill-current ${
          props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
        }`}
      />
      <p>
        Competition Level:{' '}
        <span className={`capitalize`}>
          {props.pack.detailSettings.competitionLevel}
        </span>
      </p>
    </div>
  );
}

export function GamePackTeamMode(props: { pack: GamePack; strong?: boolean }) {
  const isPlayTogether = isGamePackPlayTogether(props.pack);

  return (
    <div
      className={`flex-shrink-0 flex items-center ${
        props.strong ? 'gap-2 text-base font-bold' : 'gap-1.5 text-sms'
      }`}
    >
      {isPlayTogether ? (
        <AllTogetherIcon
          className={`fill-current ${
            props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
          }`}
        />
      ) : (
        <MedalIcon
          className={`fill-current ${
            props.strong ? 'w-6 h-6 text-tertiary' : 'w-4 h-4'
          }`}
        />
      )}
      <p>{isPlayTogether ? 'Play all together' : 'Play on a team'}</p>
    </div>
  );
}

export function GamePackPrice(props: {
  pack: GamePack;
  features: IFeatureChecker;
}) {
  const { features, pack } = props;
  const enabled = useFeatureQueryParam('game-pack-card-price');
  const priceTable = GamePackUtils.ActivePrices(pack);
  if (
    !enabled ||
    priceTable.length === 0 ||
    !features.canPurchaseGamePackForOnd(pack)
  )
    return null;
  const sortedPrices = priceTable.sort((a, b) => a.amount - b.amount);
  return (
    <div
      className={`flex items-center justify-center text-3xs font-medium gap-1 w-max 
      absolute left-1/2 transform -translate-x-1/2 bottom-1 text-tertiary`}
    >
      Starting from {formatCurrency(sortedPrices[0].amount)}
    </div>
  );
}

export function GamePackPlayable(props: { pack: GamePack }) {
  const played = props.pack.played;
  const enabled = props.pack.replayable || played;
  if (!enabled) return null;
  return (
    <div
      className={`flex items-center justify-center text-3xs font-medium gap-1 w-max absolute left-1/2 transform -translate-x-1/2 bottom-2 uppercase ${
        props.pack.replayable ? 'text-green-001 opacity-50' : 'text-[#4C5051]'
      }`}
    >
      {props.pack.replayable ? (
        <RefreshIcon className='w-3 h-3 fill-current' />
      ) : (
        <FilledCheckIcon className='w-3 h-3 fill-current' />
      )}{' '}
      {props.pack.replayable ? 'Replayable' : 'Completed'}
    </div>
  );
}
