import { useEffect, useMemo, useRef, useState } from 'react';

import {
  type DtoBrand,
  EnumsMediaType,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import placeholder from '../../../src/assets/img/placeholder/game-cover.png';
import { fromMediaDTO } from '../../../src/utils/api-dto';
import { MediaUtils } from '../../../src/utils/media';

function ShowcaseVideo(props: {
  media: Media;
  t?: string;
  loop?: boolean;
  play?: boolean;
  className?: string;
}) {
  const { media, t, loop, play, className } = props;

  const ref = useRef<HTMLVideoElement | null>(null);

  const url = useMemo(() => {
    const url = MediaUtils.PickMediaUrl(media) || '';
    return t ? `${url}#t=${t}` : url;
  }, [media, t]);

  useEffect(() => {
    if (!ref.current) return;
    if (play) {
      ref.current.currentTime = 0;
      ref.current.play();
    } else ref.current.pause();
  });

  return (
    <video
      ref={ref}
      src={url}
      loop={loop}
      muted
      className={`w-full h-full rounded-lg object-cover ${className}`}
    />
  );
}

function ShowcaseImage(props: { media: Nullable<Media>; className?: string }) {
  const { media, className } = props;

  const url = useMemo(
    () => MediaUtils.PickMediaUrl(media) || placeholder,
    [media]
  );

  return (
    <img
      className={`w-full h-full object-cover rounded-lg ${className}`}
      src={url}
      alt='luna-park'
    />
  );
}

export function GamePackHoverBrandsPreviewMulti(props: {
  brands: DtoBrand[];
  play: boolean;
  indicator: 'inner' | 'outer';
  mediaClassName?: string;
}) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!props.play) return;

    const timer = setInterval(() => {
      setIndex((current) => (current + 1) % props.brands.length);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [props.brands.length, props.play]);

  return (
    <div className='w-full h-full relative flex flex-col items-center'>
      {props.brands.map((brand, i) => {
        const selected = i === index;
        const media = fromMediaDTO(brand.showcaseMedia);
        if (media && media.type === MediaType.Video) {
          const durationSec = MediaUtils.GetMediaDurationMs(media) / 1000;
          const t =
            durationSec > 6
              ? '3,6'
              : durationSec > 3
              ? `${durationSec - 3},`
              : '';
          return (
            <ShowcaseVideo
              key={brand.id}
              media={media}
              t={t}
              loop
              className={`${props.mediaClassName} ${selected ? '' : 'hidden'}`}
              play={props.play && selected}
            />
          );
        }
        return selected ? (
          <ShowcaseImage
            key={brand.id}
            media={media}
            className={`${props.mediaClassName}`}
          />
        ) : null;
      })}

      <div
        className={`absolute w-2/3 flex items-center justify-center gap-2`}
        style={{
          bottom: props.indicator === 'inner' ? '3%' : '103%',
        }}
      >
        {props.brands.map((_, i) => (
          <div
            key={i}
            className={`w-1/12 rounded-1.5lg bg-white ${
              i === index ? 'opacity-100' : ' opacity-20'
            }`}
            style={{
              aspectRatio: '4 / 1',
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export function GamePackHoverBrandsPreviewSingle(props: {
  brand: DtoBrand;
  play: boolean;
  mediaClassName?: string;
}) {
  const media = props.brand.showcaseMedia;
  if (media?.type === EnumsMediaType.MediaTypeVideo) {
    return (
      <ShowcaseVideo
        media={fromMediaDTO(media)}
        loop
        play={props.play}
        className={props.mediaClassName}
      />
    );
  }
  return (
    <ShowcaseImage
      media={fromMediaDTO(media)}
      className={props.mediaClassName}
    />
  );
}
