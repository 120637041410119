import { isAfter } from 'date-fns';
import { $path } from 'remix-routes';
import short from 'short-uuid';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoTag,
  EnumsBlockType,
  EnumsEventType,
  type ModelsVoiceOver,
} from '@lp-lib/api-service-client/public';

import { selectOptionalConfig } from '../../../src/components/Game/Blocks/Randomizer/utils';
import { type Tag } from '../../../src/types';
import { type GamePack } from '../../../src/types/game';

const shorter = short();

export function encodeShortId(packId: string) {
  return shorter.fromUUID(packId);
}

export function decodeShortId(shortId: string) {
  return shorter.toUUID(shortId);
}

export function makeAnonPackUrl(
  pack: DtoGamePack | GamePack,
  options?: {
    tag?: Tag | DtoTag | null;
  }
) {
  const params = new URLSearchParams();
  if (options?.tag) {
    params.set('tag', options.tag.slug);
  }
  let url = `/experiences/${encodeShortId(pack.id)}`;
  if (params.toString()) {
    url = `${url}?${params.toString()}`;
  }
  return url;
}

export function makeAnonGamePackTagUrl(tag: DtoTag | Tag) {
  return `/explore/tags/${tag.slug}`;
}

export function makeGamePackTagUrl(
  tag: DtoTag | Tag,
  options?: {
    anonymous?: boolean;
  }
) {
  return options?.anonymous ? `/explore/tags/${tag.slug}` : `/tags/${tag.slug}`;
}

export function makeSearchUrl(q: string, options?: { anonymous?: boolean }) {
  return options?.anonymous ? `/explore/search?q=${q}` : `/search?q=${q}`;
}

export function getVoiceOverList(block: DtoBlock): ModelsVoiceOver[] {
  switch (block.type) {
    case EnumsBlockType.BlockTypeScoreboard:
    case EnumsBlockType.BlockTypeSpotlight:
    case EnumsBlockType.BlockTypeSpotlightV2:
      return block.fields && block.fields.voiceOver
        ? [block.fields.voiceOver]
        : [];
    case EnumsBlockType.BlockTypeTitleV2:
      return (
        block.fields.cards
          ?.map((c: { voiceOver: unknown | null }) => c.voiceOver)
          ?.filter(Boolean) || []
      );
    default:
      return [];
  }
}

export function isGamePackPlayTogether(pack: DtoGamePack | GamePack) {
  if (pack.teamRandomizationSettings?.oneTeam) return true;
  const config = selectOptionalConfig(pack.teamRandomizationSettings, 100);
  return config.teamSize === 1;
}

export function isGamePackLaunched(
  pack: DtoGamePack | GamePack,
  targetDate = new Date()
) {
  if (!pack.detailSettings?.availability) return true;
  return isAfter(
    targetDate,
    new Date(pack.detailSettings.availability.launchDate)
  );
}

export function isGamePackCohosted(pack: DtoGamePack | GamePack) {
  return !!pack.cohostSettings?.enabled;
}

export function makeGamePackScheduleLink(pack: DtoGamePack | GamePack) {
  if (isGamePackCohosted(pack)) {
    return $path(
      '/events/schedule/:type?',
      {
        type: EnumsEventType.EventTypeLive,
      },
      { 'game-pack-id': pack.id }
    );
  }
  return `/events/create?game-pack-id=${pack.id}`;
}

export function makeGamePackPurchaseLink(
  pack: DtoGamePack | GamePack,
  extra = {}
) {
  return $path('/checkout', {
    'pack-id': pack.id,
    ...extra,
  });
}
